export const slideStyles = {
  width: '180px',
  height: '180px',
  marginRight: '24px',
  borderRadius: '8px',
};

export const seenCss = {
  border: '1px solid $gray300',
};

export const unseenCss = {
  border: '3px solid $primary600',
};
