import { InsightsDialog as KontaInsightsDialog } from '@konta/ui';
import { utils } from 'shared/components/XyFlow';
import { useInsightsDialog } from '../InsightsDialogContext';

export default function InsightsDialog() {
  const {
    isOpen,
    setIsOpen,
    taxableEntityFlows,
    currentFlowIndex,
    currentSlideIndex,
    handleFlowChange,
    handleSlideChange,
    isActiveDialog,
    setIsActiveDialog,
    previewContainerCss,
    setPreviewContainerCss,
  } = useInsightsDialog();

  const insightsDialogItems = taxableEntityFlows.map((taxableEntityFlow) => ({
    ...taxableEntityFlow,
    slides: taxableEntityFlow.steps.map((taxableEntityFlowStep) => ({
      ...taxableEntityFlowStep,
      content: utils.getStepContent(taxableEntityFlowStep.flow_step, {
        setIsActiveDialog,
        setIsOpen,
        setPreviewContainerCss,
      }),
    })),
  }));

  if (!isOpen) return null;
  return (
    <KontaInsightsDialog
      contentCss={previewContainerCss}
      isActiveDialog={isActiveDialog}
      open={isOpen}
      onOpenChange={setIsOpen}
      headerProps={{
        avatars: [],
        text: '',
      }}
      flows={insightsDialogItems}
      currentFlowIndex={currentFlowIndex}
      currentSlideIndex={currentSlideIndex}
      onFlowChange={handleFlowChange}
      onSlideChange={handleSlideChange}
    />
  );
}
