import { NodeProps as XyFlowNodeProps } from '@xyflow/react';
import { DateStr } from './entities';

export interface NodeData extends Record<string, unknown> {
  label: string;
  isFirst?: boolean;
  isLast?: boolean;
  step: Step;
  flow_id: number;
}

export interface NodeProps extends XyFlowNodeProps {
  data: NodeData;
}

export interface Edge {
  id: number;
  source_step_id: number;
  target_step_id: number;
  created_at: DateStr;
  updated_at: DateStr;
}

export enum StepDataType {
  IMG = 'image',
  TEXT = 'text',
  BLOG = 'blog',
}
export interface CommonStep {
  id: number;
  flow_id: number;
  readonly created_at?: DateStr;
  readonly updated_at?: DateStr;
}
export interface TextStep extends CommonStep {
  type: StepDataType.TEXT;
  params: {
    body: string;
    emoji: string;
    title: string;
  };
}
export interface ImgStep extends CommonStep {
  type: StepDataType.IMG;
  params: {
    url: string;
  };
}
export interface BlogStep extends CommonStep {
  type: StepDataType.BLOG;
  params: {
    url: string;
  };
}
export type Step = TextStep | ImgStep | BlogStep;

export enum FlowTDataType {
  INSIGHT = 'insight',
}

export interface CommonFlow {
  id: number;
  name: string;
  readonly created_at?: DateStr;
  readonly updated_at?: DateStr;
  steps: Step[];
  edges: Edge[];
}

export interface FlowInsight extends CommonFlow {
  type: FlowTDataType.INSIGHT;
  image: {
    content: string;
    content_type: string;
  };
}

export type Flow = FlowInsight;

export interface TaxableEntityFlowStep {
  id: number;
  flow_step_id: number;
  taxable_entity_flow_id: number;
  state: Record<string, unknown>;
  seen: boolean;
  completed: boolean;
  created_at: DateStr;
  updated_at: DateStr;
  flow_step: Step;
}

export interface TaxableEntityFlow {
  id: number;
  taxable_entity_id: number;
  flow_id: number;
  status: string | null;
  created_at: DateStr;
  updated_at: DateStr;
  steps: TaxableEntityFlowStep[];
  flow: Omit<Flow, 'steps'>;
}
