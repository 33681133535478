import { ReactNode } from 'react';
import { Position } from '@xyflow/react';
import {
  FileCheck02LineIcon,
  Flag05LineIcon,
  Image01LineIcon,
  MoveLineIcon,
  SearchSmLineIcon,
  Type01LineIcon,
} from '@konta/icons';
import { NodeProps, NodeData, StepDataType } from 'types/flows';
import {
  CustomNode,
  NodeIcon,
  NodeHeader,
  NodeTitleContainer,
  NodeTitle,
  NodeContent,
  ContentText,
  ContentSearch,
  FlagIcon,
  ContentImage,
  CustomHandle,
} from './styled';

const getNodeContent = (data: NodeData): { icon: ReactNode; text: string } => {
  switch (data.step.type) {
    case StepDataType.IMG:
      return { icon: <Image01LineIcon />, text: 'Imagen' };
    case StepDataType.TEXT:
      return { icon: <Type01LineIcon />, text: 'Texto' };
    case StepDataType.BLOG:
      return { icon: <FileCheck02LineIcon />, text: 'Blog' };
    default:
      return { icon: null, text: '' };
  }
};

export default function Node({ data }: NodeProps) {
  const { icon, text } = getNodeContent(data);
  return (
    <CustomNode>
      <NodeHeader>
        <NodeIcon>
          <MoveLineIcon />
        </NodeIcon>
        <NodeTitleContainer>
          <NodeTitle>{data.label}</NodeTitle>
          {(data.isFirst || data.isLast) && (
            <FlagIcon>
              <Flag05LineIcon />
            </FlagIcon>
          )}
        </NodeTitleContainer>
      </NodeHeader>
      <NodeContent id="nodeContent">
        <ContentImage>{icon}</ContentImage>
        <ContentText>{text}</ContentText>
        <ContentSearch>
          <SearchSmLineIcon />
        </ContentSearch>
      </NodeContent>
      {!data?.isFirst && (
        <CustomHandle
          isConnectable={false}
          type="target"
          position={Position.Left}
        />
      )}
      {!data?.isLast && (
        <CustomHandle
          isConnectable={false}
          type="source"
          position={Position.Right}
        />
      )}
    </CustomNode>
  );
}
