import {
  CarouselItem,
  useCarouselControl,
  useMediaQuery,
  config,
} from '@konta/ui';
import dayjs from 'dayjs';
import { useInsightsDialog } from './InsightsDialogContext';

export default function useInsights() {
  const { handleFlowSelect, taxableEntityFlows } = useInsightsDialog();
  const { carouselRef, buttonState, handleExternalPrev, handleExternalNext } =
    useCarouselControl();

  const carouselItems: CarouselItem[] = taxableEntityFlows.map(
    (taxableEntityFlow) => ({
      id: taxableEntityFlow.flow.id.toString(),
      taxableEntityFlow,
      type: 'image',
      content: `data:${taxableEntityFlow.flow.image.content_type};base64,${taxableEntityFlow.flow.image.content}`,
    }),
  );

  const isMobileOrTablet = useMediaQuery(config.media['<md']);

  const currentMonth = dayjs().format('MMMM');

  return {
    carouselItems,
    carouselRef,
    buttonState,
    handleExternalPrev,
    handleExternalNext,
    isMobileOrTablet,
    currentMonth,
    handleFlowSelect,
  };
}
