import { useMemo } from 'react';
import { IssuerAttributes } from 'types/cfdi';
import { useSelector } from 'react-redux';
import filterActiveRegimes from 'shared/util/filterActiveRegimes';
import useFiscalRegimeOptions from './useFiscalRegimeOptions';
import { IRootState } from '../types/redux';
import { FiscalRegime, ReactSelectItemProps } from '../types/entities';
import usePreferredFiscalRegime from '../shared/hooks/usePreferredFiscalRegime';

export default function useIssuerAttributesParser(
  issuerAttributes?: IssuerAttributes,
) {
  const { preferredFiscalRegime } = usePreferredFiscalRegime();
  const taxableEntity = useSelector(
    (state: IRootState) => state.taxableEntity.taxable_entity,
  );
  const legalName = issuerAttributes?.name || taxableEntity?.legal_name || '';
  const activeFiscalRegimes = filterActiveRegimes(
    taxableEntity?.fiscal_regimes || [],
  );
  // @ts-ignore TODO: refactor this hook to use typescript
  const { regimeOptions } = useFiscalRegimeOptions({
    availableRegimes: activeFiscalRegimes,
  }) as {
    regimeOptions: ReactSelectItemProps<FiscalRegime>[];
  };

  const fiscalRegime = useMemo(() => {
    const selectedFiscalRegime = regimeOptions?.find?.(
      (opt) => opt.value.sat_key === preferredFiscalRegime,
    );
    if (selectedFiscalRegime) {
      return selectedFiscalRegime;
    }
    if (regimeOptions?.length) {
      return regimeOptions[0];
    }
    return null;
  }, [preferredFiscalRegime, regimeOptions]);

  return {
    legalName,
    fiscalRegime,
  };
}
