import {
  Text,
  Flex,
  Button,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@konta/ui';
import Main from '@components/DesignSystem/Main';
import Section from 'shared/components/Section';
import IncomesLineSeries from 'shared/components/IncomesLineSeries';
import ExpensesLineSeries from 'shared/components/ExpensesLineSeries';
import MetricsGroup from 'shared/components/MetricsGroup';
import useMetricGroup from 'shared/components/MetricsGroup/useMetricGroup';
import { openTour } from '@util/Intercom';
import useFeature from '@hooks/useFeature';
import { INSIGHTS_DASHBOARD } from '@constants/featureFlags';
import Heatmap from './Heatmap';
import Insights from './Insights';
import {
  Container,
  Content,
  Divider,
  Header,
  Root,
  TextAndSupportingText,
  Actions,
  BookOpenIcon,
} from './styled';
import NotCiecModal from './NotCiecModal';
import { InsightsDialogProvider } from './Insights/InsightsDialogContext';

export default function InformationDashboard() {
  const { complianceOpinionStatusLabel, complianceOpinionStatusLoading } =
    useMetricGroup();
  const isViewEnabled = complianceOpinionStatusLabel !== 'Sin opinión';
  const [isInsightsEnabled] = useFeature(INSIGHTS_DASHBOARD);

  return (
    <Root blockUi={!isViewEnabled}>
      {!isViewEnabled && (
        <NotCiecModal isLoading={complianceOpinionStatusLoading} />
      )}
      <Main blockUi={!isViewEnabled || complianceOpinionStatusLoading}>
        <Container>
          <Header>
            <Content>
              <TextAndSupportingText grow={1}>
                <Text bold xl lineHeight="xl">
                  Tu resumen 😉
                </Text>
              </TextAndSupportingText>
              <Actions>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="minimal"
                      size="s"
                      noFill
                      leftIcon={<BookOpenIcon />}
                    >
                      Aprende
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <Flex align="center">
                      <Text
                        s
                        css={{ maxWidth: '250px', padding: '0.625rem 1rem' }}
                      >
                        En esta vista podrás ver los indicadores clave de tu
                        contabilidad y de tu negocio
                      </Text>
                    </Flex>
                    <DropdownMenuItem onClick={() => openTour(465781)}>
                      <Flex align="center">
                        <BookOpenIcon />
                        <Text s css={{ ml: '$12' }}>
                          Tomar explicación de vista
                        </Text>
                      </Flex>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </Actions>
            </Content>
            <Divider />
          </Header>
          <Section alignStart>
            <Flex
              column
              css={{
                flex: 1,
                alignItems: 'center',
                alignSelf: 'stretch',
                gap: 16,
                overflow: 'auto',
              }}
            >
              <Section>
                <MetricsGroup />
              </Section>
              {isInsightsEnabled && (
                <Section>
                  <InsightsDialogProvider>
                    <Insights />
                  </InsightsDialogProvider>
                </Section>
              )}
              <Section>
                <IncomesLineSeries />
                <ExpensesLineSeries />
              </Section>
            </Flex>
          </Section>
          <Section css={{ mt: '$18' }}>
            <Heatmap />
            <Flex css={{ flex: 1 }} />
          </Section>
        </Container>
      </Main>
    </Root>
  );
}
