import { type Step, StepDataType } from 'types/flows';
import type { CSS } from '@konta/ui';
import TextStepContent from './InsightsContent/TextStepContent';
import ImageStepContent from './InsightsContent/ImageStepContent';
import BlogStepContent from './InsightsContent/BlogStepContent';

// eslint-disable-next-line import/prefer-default-export
export const getStepContent = (
  step: Step,
  {
    setIsActiveDialog,
    setIsOpen,
    setPreviewContainerCss,
  }: {
    setIsActiveDialog?: (isActiveDialog: boolean) => void;
    setIsOpen?: (isOpen: boolean) => void;
    setPreviewContainerCss?: (css: CSS) => void;
  },
) => {
  switch (step.type) {
    case StepDataType.TEXT:
      return <TextStepContent step={step} />;
    case StepDataType.IMG:
      return (
        <ImageStepContent
          step={step}
          setPreviewContainerCss={setPreviewContainerCss}
        />
      );
    case StepDataType.BLOG:
      return (
        <BlogStepContent
          step={step}
          setIsActiveDialog={setIsActiveDialog}
          setIsOpen={setIsOpen}
        />
      );
    default:
      return null;
  }
};
