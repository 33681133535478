import { Section, Carousel } from '@konta/ui';
import { TaxableEntityFlow } from 'types/flows';
import Navigation from './Navigation';
import Subtitle from './Subtitle';
import InsightsDialog from './InsightsDialog';
import useInsights from './useInsights';
import { slideStyles, seenCss, unseenCss } from './styled';

export default function Insights() {
  const {
    carouselItems,
    carouselRef,
    buttonState,
    handleExternalPrev,
    handleExternalNext,
    isMobileOrTablet,
    currentMonth,
    handleFlowSelect,
  } = useInsights();
  if (!carouselItems.length) return null;
  return (
    <>
      <InsightsDialog />
      <Section
        title={`Tus insights mensuales: ${currentMonth} 👍🏼`}
        subtitle={<Subtitle isMobileOrTablet={isMobileOrTablet} />}
        subtitleRightElement={
          <Navigation
            handleExternalPrev={handleExternalPrev}
            handleExternalNext={handleExternalNext}
            buttonState={buttonState}
            isVisible={!isMobileOrTablet}
          />
        }
        content={
          <Carousel
            items={carouselItems}
            ref={carouselRef}
            externalControl
            slideStyles={(item) => {
              const flow = item.taxableEntityFlow as TaxableEntityFlow;
              return {
                ...slideStyles,
                ...(flow?.status === 'seen' ? seenCss : unseenCss),
              };
            }}
            onButtonStateChange={buttonState.setButtonState}
            onSlideClick={(item, index) => {
              handleFlowSelect(index);
            }}
          />
        }
      />
    </>
  );
}
