import { CSS } from '@konta/ui';
import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useMemo,
} from 'react';
import useTaxableEntityFlows from 'shared/hooks/useTaxableEntityFlows';
import { Flow, TaxableEntityFlow } from 'types/flows';

export interface SlideContent {
  [key: string]: unknown;
  content: ReactNode;
}

interface InsightsDialogContextType {
  isOpen: boolean;
  currentFlowIndex: number;
  currentSlideIndex: number;
  taxableEntityFlows: TaxableEntityFlow[];
  setIsOpen: (isOpen: boolean) => void;
  handleFlowSelect: (flowIndex: number) => void;
  handleFlowChange: (newFlowIndex: number) => void;
  handleSlideChange: (newSlideIndex: number) => void;
  onFlowVisit?: (flow: Flow, flowIndex: number) => void;
  onStepVisit?: (
    step: SlideContent,
    slideIndex: number,
    flow: Flow,
    flowIndex: number,
  ) => void;
  onFlowComplete?: (flow: Flow, flowIndex: number) => void;
  isActiveDialog: boolean;
  setIsActiveDialog: (isActiveDialog: boolean) => void;
  setPreviewContainerCss: (css: CSS) => void;
  previewContainerCss: CSS;
}

const InsightsDialogContext = createContext<
  InsightsDialogContextType | undefined
>(undefined);

export function InsightsDialogProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { taxableEntityFlows } = useTaxableEntityFlows();
  const [previewContainerCss, setPreviewContainerCss] = useState({});
  const [isActiveDialog, setIsActiveDialog] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [currentFlowIndex, setCurrentFlowIndex] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const handleFlowSelect = useCallback((flowIndex: number) => {
    setCurrentFlowIndex(flowIndex);
    setCurrentSlideIndex(0);
    setIsOpen(true);
  }, []);

  const handleFlowChange = useCallback((newFlowIndex: number) => {
    setCurrentFlowIndex(newFlowIndex);
    setCurrentSlideIndex(0);
  }, []);

  const handleSlideChange = useCallback((newSlideIndex: number) => {
    setCurrentSlideIndex(newSlideIndex);
  }, []);

  const onFlowVisit = useCallback((flow: Flow, flowIndex: number) => {
    console.log('Flow visited', flow, flowIndex);
  }, []);

  const onStepVisit = useCallback(
    (step: SlideContent, slideIndex: number, flow: Flow, flowIndex: number) => {
      console.log('Step visited', step, flow, flowIndex, slideIndex);
    },
    [],
  );

  const onFlowComplete = useCallback((flow: Flow, flowIndex: number) => {
    console.log('Flow complete', flow, flowIndex);
  }, []);

  const value = useMemo(
    () => ({
      isOpen,
      currentFlowIndex,
      currentSlideIndex,
      taxableEntityFlows,
      setIsOpen,
      handleFlowChange,
      handleSlideChange,
      onFlowVisit,
      onStepVisit,
      onFlowComplete,
      handleFlowSelect,
      isActiveDialog,
      setIsActiveDialog,
      setPreviewContainerCss,
      previewContainerCss,
    }),
    [
      isOpen,
      currentFlowIndex,
      currentSlideIndex,
      taxableEntityFlows,
      setIsOpen,
      handleFlowChange,
      handleSlideChange,
      onFlowVisit,
      onStepVisit,
      onFlowComplete,
      handleFlowSelect,
      isActiveDialog,
      setIsActiveDialog,
      setPreviewContainerCss,
      previewContainerCss,
    ],
  );

  return (
    <InsightsDialogContext.Provider value={value}>
      {children}
    </InsightsDialogContext.Provider>
  );
}

export const useInsightsDialog = () => {
  const context = useContext(InsightsDialogContext);
  if (context === undefined) {
    throw new Error(
      'useInsightsDialog must be used within an InsightsDialogProvider',
    );
  }
  return context;
};
